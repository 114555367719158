import { Modal } from "@components/Modal";
import { Column, Table } from "@components/Table";
import { Order, User, useOrdersQuery } from "@graphql/";
import { Box, Button, Chip, Typography } from "@mui/material";
import { useApplicationContext } from "@src/context";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { OrderMobileRow } from "@components/MobileTableRows/OrderMobileRow";
import { useOrderStatuses, useUsersMutations } from "@src/hooks";
import { useNavigate } from "react-router-dom";
import { ORDER_STATUSES } from "@utils/generatedConstants";

interface ConfirmDeleteMyAccountModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  user: User;
}

export const ConfirmDeleteMyAccountModal: FC<
  ConfirmDeleteMyAccountModalProps
> = ({ open, setOpen, user }) => {
  const { t } = useTranslation();
  const { isCustomer } = useApplicationContext();
  const { getOrderByStatus } = useOrderStatuses();
  const navigate = useNavigate();
  const { updateUser, updatingUser, deleteUser, deletingUser } =
    useUsersMutations();
  const ordersQuery = useOrdersQuery({
    variables: {
      filter: {
        status: {
          in: [
            ORDER_STATUSES.NEW,
            ORDER_STATUSES.NOT_VERIFIED,
            ORDER_STATUSES.UNPAID,
            ORDER_STATUSES.PAID,
            ORDER_STATUSES.READY,
            ORDER_STATUSES.ASSIGNED,
          ],
        },
        or: [
          {
            idCustomer: { eq: user.id },
          },
          {
            idVendor: { eq: user.id },
          },
          {
            idDeliveryMan: { eq: user.id },
          },
        ],
      },
    },
  });

  const orders = ordersQuery.data?.orders.nodes || [];

  const columns: Column[] = [
    {
      label: t("order.list.label.ref"),
      name: "ref",
      sortable: true,
    },
    {
      label: t("order.list.label.dateTime"),
      name: "datetime",
      renderer: (row: Order) => {
        return moment(row.datetime).format("DD/MM/YYYY à HH:mm");
      },
      breaking: true,
      sortable: true,
    },
    {
      label: t("order.list.label.status"),
      name: "status",
      renderer: (row: Order) => {
        const status = getOrderByStatus(row.status);
        return (
          <Chip
            label={status.label}
            sx={{ color: "#FFFFFF", background: status.color }}
          />
        );
      },
      sortable: true,
    },
    {
      label: isCustomer
        ? t("order.list.label.pharmacy")
        : t("order.list.label.customer"),
      name: isCustomer ? "vendor_infoLegale_name" : "customer_firstName",
      renderer: (row: Order) => {
        return isCustomer
          ? row.vendor?.infoLegale?.name.split("(")[0]
          : row.customer
          ? `${row.customer?.firstName} ${row.customer?.lastName}`
          : "Utilisateur supprimé";
      },
      breaking: true,
      sortable: true,
    },
    {
      label: t("order.list.label.deliveryAddress"),
      name: "address_name",
      renderer: (row: Order) => {
        return row.address
          ? `${row.address.name} ${row.address.city} ${row.address.zipcode}`
          : "Adresse supprimée";
      },
      breaking: true,
      sortable: true,
    },
  ];

  const pendingOrders = orders.filter((order) =>
    [
      ORDER_STATUSES.NEW,
      ORDER_STATUSES.NOT_VERIFIED,
      ORDER_STATUSES.UNPAID,
    ].includes(order.status)
  );

  const inProgressOrders = orders.filter((order) =>
    [
      ORDER_STATUSES.PAID,
      ORDER_STATUSES.READY,
      ORDER_STATUSES.ASSIGNED,
    ].includes(order.status)
  );

  const allOrders = [...pendingOrders, ...inProgressOrders];

  const handleToggleOpenConfirmDialog = async () => {
    if (user.status === "BEING_DELETED") {
      await updateUser({
        variables: {
          input: { id: user.id, update: { status: "EMAIL_VERIFIED" } },
        },
      });
    }
    setOpen(!open);
  };

  const handleUpdateStatusToBeingDeleted = async () => {
    if (!user.id) {
      return;
    }
    updateUser({
      variables: {
        input: { id: user.id, update: { status: "BEING_DELETED" } },
      },
    }).then(() => {
      handleToggleOpenConfirmDialog();
    });
  };

  const logout = () => {
    localStorage.clear();
    navigate("/signin");
    window.location.reload();
  };

  const handleDeleteUser = () => {
    if (!user.id) {
      return;
    }
    deleteUser({
      variables: {
        input: { id: user.id },
      },
    }).then(() => {
      logout();
    });
  };

  const loading = updatingUser.loading || deletingUser.loading;

  return (
    <Modal
      open={open}
      onClose={handleToggleOpenConfirmDialog}
      title={`${user.firstName} ${user.lastName}`}
      maxWidth="xl"
      disableCloseBtn={updatingUser.loading}
    >
      <Typography mb={4}>{t("user.list.deletion.caution")}</Typography>

      {user.entity?.code === "PHARMACY" ? (
        pendingOrders.length + inProgressOrders.length === 0 ? (
          <Box>
            <Typography mb={4}>
              {t("user.list.deletion.withoutPendingOrders")}
            </Typography>
            {user.status === "BEING_DELETED" ? (
              <Typography>
                {t("user.list.deletion.beingDeletedWithoutPendingOrders")}
              </Typography>
            ) : (
              <Typography>
                {t("user.list.deletion.withoutPendingOrders")}
              </Typography>
            )}
          </Box>
        ) : inProgressOrders.length ? (
          <Box>
            <Typography mb={4}>
              {t("user.list.deletion.inProgressOrdersCount", {
                count: inProgressOrders.length,
              })}
            </Typography>
            {user.status === "BEING_DELETED" ? (
              <Typography>
                {t(
                  "user.list.deletion.PHARMACY.beingDeletedWithInProgressOrders"
                )}
              </Typography>
            ) : (
              <Typography>
                {t("user.list.deletion.PHARMACY.withInProgressOrders")}
              </Typography>
            )}
            <Table
              columns={columns}
              data={inProgressOrders}
              loading={ordersQuery.loading}
              mobileTableRow={<OrderMobileRow />}
              infiniteScrollHeight="calc(100vh - 300px)"
              model="order"
            />
          </Box>
        ) : pendingOrders.length ? (
          <Box>
            <Typography mb={4}>
              {t("user.list.deletion.pendingOrdersCount", {
                count: pendingOrders.length,
              })}
            </Typography>
            {user.status === "BEING_DELETED" ? (
              <Typography>
                {t("user.list.deletion.PHARMACY.beingDeletedWithPendingOrders")}
              </Typography>
            ) : (
              <Typography>
                {t("user.list.deletion.PHARMACY.withPendingOrders")}
              </Typography>
            )}
            <Table
              columns={columns}
              data={pendingOrders}
              loading={ordersQuery.loading}
              mobileTableRow={<OrderMobileRow />}
              infiniteScrollHeight="calc(100vh - 300px)"
              model="order"
            />
          </Box>
        ) : (
          <></>
        )
      ) : user.entity?.code === "LIVREUR" ? (
        !inProgressOrders.length ? (
          <Box>
            <Typography mb={4}>
              {t("user.list.deletion.withoutPendingOrders")}
            </Typography>
            {user.status === "BEING_DELETED" ? (
              <Typography>
                {t("user.list.deletion.beingDeletedWithoutPendingOrders")}
              </Typography>
            ) : (
              <Typography>
                {t("user.list.deletion.withoutPendingOrders")}
              </Typography>
            )}
          </Box>
        ) : (
          <Box>
            <Typography mb={4}>
              {t("user.list.deletion.inProgressOrdersCount", {
                count: inProgressOrders.length,
              })}
            </Typography>
            {user.status === "BEING_DELETED" ? (
              <Typography>
                {t(
                  "user.list.deletion.LIVREUR.beingDeletedWithInProgressOrders"
                )}
              </Typography>
            ) : (
              <Typography>
                {t("user.list.deletion.LIVREUR.withInProgressOrders")}
              </Typography>
            )}
            <Table
              columns={columns}
              data={inProgressOrders}
              loading={ordersQuery.loading}
              mobileTableRow={<OrderMobileRow />}
              infiniteScrollHeight="calc(100vh - 300px)"
              model="order"
            />
          </Box>
        )
      ) : ["INDIVIDUAL", "PROFESSIONAL"].includes(user.entity?.code || "") ? (
        allOrders.length === 0 ? (
          <Box>
            <Typography>
              {t("user.list.deletion.CUSTOMER.withoutPendingOrders")}
            </Typography>
          </Box>
        ) : (
          <Box>
            <Typography mb={4}>
              {t("user.list.deletion.inProgressOrdersCount", {
                count: allOrders.length,
              })}
            </Typography>
            <Typography>
              {t("user.list.deletion.CUSTOMER.withInProgressOrders")}
            </Typography>

            <Table
              columns={columns}
              data={allOrders}
              loading={ordersQuery.loading}
              mobileTableRow={<OrderMobileRow />}
              infiniteScrollHeight="calc(100vh - 300px)"
              model="order"
            />
          </Box>
        )
      ) : (
        <></>
      )}
      <Box display="flex" justifyContent="flex-end" mt={4}>
        {!["INDIVIDUAL", "PROFESSIONAL"].includes(user.entity?.code || "") && (
          <>
            <Button
              sx={{ marginRight: 2 }}
              variant="contained"
              color="error"
              onClick={handleUpdateStatusToBeingDeleted}
              disabled={loading || user.status === "BEING_DELETED"}
            >
              {t("user.list.deletion.stopTakingOrders")}
            </Button>
            <Button
              sx={{ marginRight: pendingOrders.length ? 2 : 0 }}
              variant="contained"
              color="primary"
              onClick={handleToggleOpenConfirmDialog}
              disabled={loading}
            >
              {t("user.list.deletion.continueReceivingOrders")}
            </Button>
          </>
        )}
        {((["PHARMACY", "LIVREUR"].includes(user.entity?.code || "") &&
          !inProgressOrders.length) ||
          (["INDIVIDUAL", "PROFESSIONAL"].includes(user.entity?.code || "") &&
            !allOrders.length)) && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleDeleteUser}
            disabled={loading}
            sx={{ marginLeft: 2 }}
          >
            {t("user.list.deletion.deleteMyAccount")}
          </Button>
        )}
      </Box>
    </Modal>
  );
};
