import {
  useNotificationsQuery,
  Notification as NotificationType,
  useNotifications_CountQuery,
  SupportTicket,
  useNotificationSubscriptionSubscription,
} from "@graphql/";
import { Notifications as NotificationsIcon } from "@mui/icons-material";
import {
  Badge,
  Box,
  Divider,
  IconButton,
  Popover,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { useApplicationContext } from "@src/context";
import { useNotificationsMutations, usePagination } from "@src/hooks";
import moment from "moment";
import React, { FC, useState, UIEvent } from "react";
import { useTranslation } from "react-i18next";
import { useApolloClient } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { ORDER_STATUSES } from "@utils/constants";

export const Notifications: FC = () => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [notifications, setNotifications] = useState<NotificationType[]>([]);
  const pagination = usePagination();
  const { notify, connectedUser, messagerie, connectedUserType } = useApplicationContext();
  const { updateManyNotifications } = useNotificationsMutations();
  const client = useApolloClient();
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (unreadNotificationsCount) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    updateManyNotifications({
      variables: {
        input: {
          filter: {
            idUser: { eq: connectedUser.id },
          },
          update: { status: "read" },
        },
      },
    });
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const notificationsQuery = useNotificationsQuery({
    variables: {
      filter: { status: { eq: "unread" } },
      paging: pagination.offsetPaging,
    },
    onCompleted: (data) => {
      setNotifications((prev) => [
        ...prev,
        ...(data.notifications.nodes as NotificationType[]),
      ]);
      pagination.setTotalCount(data.notifications.totalCount);
    },
  });

  const unreadNotificationsCountQuery = useNotifications_CountQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      filter: {
        status: { eq: "unread" },
      },
    },
    skip: connectedUserType === "SUPERADMIN"
  });

  const unreadNotificationsCount =
    unreadNotificationsCountQuery.data?.notifications.totalCount;

  const generateNotificationMessage = (notification: NotificationType) => {
    if(notification.category === "BUG") {
      return `Bug sur la mutation "${notification.action}"`
    }
    return t(
      `notification.${notification.category}.${notification.action}`,
      notification.data
    );
  };

  const fetchMore = () => {
    pagination.setOffsetPaging({
      limit: 10,
      offset: pagination.offsetPaging.offset + 10,
    });
  };

  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    const bottom =
      e.currentTarget.scrollHeight - e.currentTarget.scrollTop ===
      e.currentTarget.clientHeight;
    if (bottom && notifications.length < pagination.totalCount) {
      fetchMore();
    }
  };

  useNotificationSubscriptionSubscription({
    variables: {
      idUser: connectedUser?.id,
    },
    skip: !connectedUser?.id,

    onSubscriptionData: ({ subscriptionData }) => {
      const { category, action, data } =
        subscriptionData?.data?.notificationSubscription;
      switch (category) {
        case "ORDER":
          client.refetchQueries({
            include: ["ORDERS", "ORDER_STATUSES_COUNTS", "VERIFY_BEARER_TOKEN"],
          });
          break;
        case "SUPPORT_TICKET":
          client.refetchQueries({
            include: ["SUPPORT_TICKETS"],
          });
          break;
        case "SUPPORT_TICKET_MESSAGE":
          client.refetchQueries({
            include: ["SUPPORT_TICKET_MESSAGES", "SUPPORT_TICKETS"],
          });
          break;
        case "LIVREUR_STRIPE_ACCOUNT_STATUS":
        case "PHARMACY_STRIPE_ACCOUNT_STATUS":
          client.refetchQueries({
            include: ["STRIPE_ACCOUNT_STATUSES_COUNT", "USERS"],
          });
          break;
        case "PHARMACY_STRIPE_PAYOUT":
        case "LIVREUR_STRIPE_PAYOUT":
          client.refetchQueries({
            include: ["ORDERS"],
          });
          break;
      }
      notificationsQuery.refetch();
      unreadNotificationsCountQuery.refetch();
      notify({
        message: generateNotificationMessage({
          category,
          action,
          data,
        } as NotificationType),
        type: "info",
      });
    },
  });

  const handleNotificationClick = (notification: NotificationType) => {
    const beginningOfMonth = moment().startOf("M").format("YYYY/MM/DD");
    const endOfMonth = moment().endOf("M").format("YYYY/MM/DD");
    switch (notification.category) {
      case "SUPPORT_TICKET_MESSAGE":
        messagerie.setSupportTicket({
          id: notification.data.id,
          ref: notification.data.ref,
        } as SupportTicket);
        break;
      case "ORDER":
        if (notification.action.startsWith("UPDATE_STATUS_")) {
          navigate(
            `/dashboard/my-orders?status=${notification.action.replace(
              "UPDATE_STATUS_",
              ""
            )}&offset=0&limit=10&from=${beginningOfMonth}&to=${endOfMonth}&collapsed=ALL_ORDERS&orderRef=${notification.data?.orderRef || ""}`
          );
        }
        switch (notification.action) {
          case ORDER_STATUSES.NEW:
            navigate(
              `/dashboard/my-orders?status=NEW&offset=0&limit=10&from=${beginningOfMonth}&to=${endOfMonth}&collapsed=ALL_ORDERS&orderRef=${notification.data?.orderRef || ""}`
            );
            break;
          case "UNASSIGNED_ORDERS":
            navigate("/dashboard/unassigned-orders");
            break;
        }
        break;
    }
  };

  return (
    <>
      <IconButton
        size="large"
        aria-describedby={id}
        onClick={handleClick}
        color="primary"
      >
        <Badge color="primary" badgeContent={unreadNotificationsCount}>
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box
          sx={{
            width: { xs: "90vw", lg: 470 },
            maxHeight: 500,
            overflowY: "scroll",
          }}
          onScroll={handleScroll}
        >
          {notifications.map((notification) => (
            <Box
              key={`notification-${notification.id}`}
              onClick={() => handleNotificationClick(notification)}
              sx={{ cursor: "pointer" }}
            >
              <Box
                sx={{
                  background:
                    notification.status === "unread" ? grey[200] : undefined,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  p: 2,
                }}
              >
                <Typography fontWeight="bold">
                  {generateNotificationMessage(notification)}
                </Typography>
                <Typography fontStyle="italic">
                  {moment(notification.createdAt).format("DD/MM/YYYY à HH:mm")}
                </Typography>
              </Box>
              <Divider />
            </Box>
          ))}
        </Box>
      </Popover>
    </>
  );
};
