import { CreateOrderState } from "@components/CreateOrderStepper";
import {
  Order,
  Ordonnance as OrdonnanceType,
  useOrdonnancesLazyQuery,
  useVerify_Reduction_CodeMutation,
} from "@graphql/";
import {
  Box,
  Button,
  Divider,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useState,
} from "react";
import { useStyles } from "./styles";
import { FileUploader } from "react-drag-drop-files";
import { useFilesMutations } from "@src/hooks";
import { useApplicationContext } from "@src/context";
import { getFileType } from "@utils/getFileType";
import { decryptFilePublicUrl } from "@utils/decryptFilePublicUrl";
import { PdfViewer } from "@components/PdfViewer/PdfViewer";
import { FILE_NAMES } from "@utils/constants";
import { useTranslation } from "react-i18next";

interface OrdonnanceProps {
  setState: Dispatch<SetStateAction<CreateOrderState | undefined>>;
  state?: CreateOrderState;
  disableNextStepBtn?: boolean;
  onPreviousStep: () => void;
  order?: Order;
  onNextStep: () => void;
}

export const Ordonnance: FC<OrdonnanceProps> = ({
  setState,
  state,
  onPreviousStep,
  disableNextStepBtn,
  order,
  onNextStep,
}) => {
  const classes = useStyles();
  const [file, setFile] = useState<File>();
  const { t } = useTranslation();
  const [fetchOrdonnance, fetchingOrdonnance] = useOrdonnancesLazyQuery();
  const { uploadFiles, isUploading } = useFilesMutations();
  const handleFileUploaderChange = (file: File) => {
    setState((prev) => ({ ...prev, ordonnance: undefined }));
    setFile(file);
  };
  const { connectedUser } = useApplicationContext();
  const idCustomer = connectedUser?.id;

  const fileTypes = ["JPEG", "PNG", "PDF", "JPG"];
  const [reduction, setReduction] = useState({
    code: "",
    message: "",
    isValid: false,
    value: 0,
  });
  const [verifyReductionCode, verifyingReductionCode] =
    useVerify_Reduction_CodeMutation({
      onError: (e) => {
        const errorCode = e.graphQLErrors[0].extensions?.["code"];
        setReduction((prev) => ({
          ...prev,
          message: t(`reduction.errors.${errorCode}`),
          isValid: false,
        }));
        setState((prev) => ({ ...prev, reductionCode: undefined }));
      },
      onCompleted: (data) => {
        setReduction((prev) => ({
          ...prev,
          message: t("reduction.valid", {reduction: data.verifyReductionCode}),
          isValid: true,
          value: data.verifyReductionCode,
        }));
        setState((prev) => ({ ...prev, reductionCode: reduction.code }));
      },
    });

  const uploadOrdonnance = () => {
    if (!file) {
      throw "file is not defined";
    }
    uploadFiles([
      {
        file: file,
        name: FILE_NAMES.ORDONNANCE,
        filename: file?.name || "",
        type: "ORDER",
        idType: order?.id,
        idUser: idCustomer,
      },
    ]).then(async (data) => {
      const ordonnanceResult = await fetchOrdonnance({
        variables: {
          filter: { file: { id: { eq: data[0].id } } },
        },
      });
      setState((prev) => ({
        ...prev,
        ordonnance: ordonnanceResult.data?.ordonnances
          .nodes[0] as OrdonnanceType,
      }));
    });
  };

  const handleReductionCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setReduction({
      code: e.target.value,
      message: "",
      isValid: false,
      value: 0,
    });
    setState((prev) => ({ ...prev, reductionCode: undefined }));
  };

  const handleVerifyReductionCode = () => {
    verifyReductionCode({ variables: { code: reduction.code } });
  };
  return (
    <>
      <Box>
        <Typography variant="h5" mb={1}>
          {t("order.list.ordonnance.title")}
        </Typography>
        {state?.ordonnance ? (
          <Box>
            <Paper
              variant="outlined"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: 1,
                maxWidth: "100%",
                mb: 1,
              }}
            >
              {getFileType(state.ordonnance.file.mimeType) === "pdf" && (
                <PdfViewer
                  url={decryptFilePublicUrl(state.ordonnance.file.url)}
                  width={600}
                />
              )}
              {getFileType(state.ordonnance.file.mimeType) === "image" && (
                <img
                  className={classes.fileContainer}
                  src={decryptFilePublicUrl(state.ordonnance.file.url)}
                  alt={`ordonnance-${state.ordonnance.file.id}`}
                  style={{ maxWidth: "100%", height: "auto" }}
                />
              )}
            </Paper>
          </Box>
        ) : (
          <Typography mb={2}>
            {t("order.list.ordonnance.uploadAValidOrdonnance")}
          </Typography>
        )}
        <FileUploader
          multiple={false}
          label={t("order.list.ordonnance.uploadAValidOrdonnance")}
          handleChange={handleFileUploaderChange}
          types={fileTypes}
        />
      </Box>
      <Divider sx={{ my: 4 }} />
      <Box display="flex" alignItems="center">
        <TextField
          label={t("reduction.label")}
          value={reduction.code}
          onChange={handleReductionCodeChange}
          sx={{ mr: 1 }}
        />
        <Button
          onClick={handleVerifyReductionCode}
          variant="contained"
          disabled={!reduction.code}
        >
          {t("commons.verify")}
        </Button>
      </Box>
      {reduction.message && !reduction.isValid && (
        <Typography variant="caption" color="red" sx={{ mt: 1 }}>
          {reduction.message}*
        </Typography>
      )}
      {reduction.message && reduction.isValid && (
        <Typography variant="caption" color="green" sx={{ mt: 1 }}>
          {reduction.message}
        </Typography>
      )}
      <Divider sx={{ my: 4 }} />
      <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: 3 }}>
        <Button
          variant="outlined"
          onClick={onPreviousStep}
          className={classes.createBtn}
          sx={{ marginRight: 2 }}
          disabled={verifyingReductionCode.loading}
        >
          {t("commons.previous")}
        </Button>
        {state?.ordonnance ? (
          <Button
            variant="contained"
            onClick={onNextStep}
            className={classes.createBtn}
            disabled={disableNextStepBtn || verifyingReductionCode.loading}
          >
            {order
              ? t("order.form.labels.update")
              : t("order.form.labels.create")}
          </Button>
        ) : (
          <Button
            variant="contained"
            onClick={uploadOrdonnance}
            className={classes.createBtn}
            disabled={isUploading || !file}
          >
            {t("commons.upload")}
          </Button>
        )}
      </Box>
    </>
  );
};
