import { Modal } from "@components/Modal";
import {
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import {
  AccessTime,
  Home,
  LocalPharmacy,
  PendingActions as PendingActionsIcon,
} from "@mui/icons-material";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useApolloClient, useQuery } from "@apollo/client";
import moment from "moment";
import { readPendingActions, removePendingAction } from "@utils/pendingActions";
import { useOrderMutations } from "@components/CreateOrderStepper/useOrderMutations";
import { PENDING_ACTIONS_QUERY } from "@utils/gqls";

export const PendingActions: FC = () => {
  const { t } = useTranslation();
  const client = useApolloClient();
  const [open, setOpen] = useState(false);
  const onClose = () => setOpen(!open);
  const pendingActionsQuery = useQuery(PENDING_ACTIONS_QUERY);
  const pendingActions = pendingActionsQuery.data?.pendingActions || [];
  const { createOneOrder, loading } = useOrderMutations();

  const handleAbort = (id: string) => {
    removePendingAction(client, id);
  };

  const handleResend = (pendingAction: any) => {
    if (pendingAction.type === "order") {
      createOneOrder({
        variables: { input: { order: pendingAction.variables } },
        onCompleted: async () => {
          await removePendingAction(client, pendingAction.id);
        },
      });
    }
  };

  return (
    <>
      <IconButton onClick={onClose}>
        <Badge badgeContent={pendingActions.length}>
          <PendingActionsIcon />
        </Badge>
      </IconButton>
      <Modal open={open} onClose={onClose} title={t("pendingActions.title")}>
        <Box sx={{ minWidth: 500 }}>
          {!pendingActions.length && (
            <Typography fontWeight="bold">
              {t("pendingActions.empty")}
            </Typography>
          )}
          {pendingActions.map((pendingAction) => (
            <Card elevation={2}>
              {pendingAction.type === "order" && (
                <CardContent>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                    <Home sx={{ mr: 1 }} />
                    <Typography>{pendingAction.address}</Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                    <LocalPharmacy sx={{ mr: 1 }} />
                    <Typography>{pendingAction.vendor}</Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                    <AccessTime sx={{ mr: 1 }} />
                    <Typography>{pendingAction.datetime}</Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <Typography fontWeight="bold">
                      {t("commons.createdAt")}:
                    </Typography>
                    <Typography>
                      {moment(pendingAction.createdAt).format(
                        "DD/MM/YYYY à HH:mm"
                      )}
                    </Typography>
                  </Box>
                  <Divider />
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mt: 2,
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      color="error"
                      sx={{ mr: 1 }}
                      onClick={() => handleAbort(pendingAction.id)}
                      disabled={loading}
                    >
                      {t("commons.cancel")}
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => handleResend(pendingAction)}
                      disabled={loading}
                    >
                      {t("commons.resend")}
                    </Button>
                  </Box>
                </CardContent>
              )}
            </Card>
          ))}
        </Box>
      </Modal>
    </>
  );
};
