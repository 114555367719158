import { useOrderMutations } from "@components/CreateOrderStepper/useOrderMutations";
import { MoreHorizMenu } from "@components/MoreHorizMenu";
import { Column } from "@components/Table";
import { Order } from "@graphql/";
import { useStripeCheckoutSessionMutations } from "@src/hooks";
import React, { Dispatch, SetStateAction } from "react";
import lodash from "lodash";
import { useTranslation } from "react-i18next";
import { ORDER_STATUSES } from "@utils/constants";

export const useCustomerProps = ({
  setOrder,
  setShowForm,
  isCustomer,
  setShowOrderDetails,
  setShowPayzenForm,
  setShowVendorForm,
}: {
  setOrder: Dispatch<SetStateAction<Order | undefined>>;
  setShowForm: Dispatch<SetStateAction<boolean>>;
  setShowPayzenForm: Dispatch<SetStateAction<boolean>>;
  isCustomer: boolean;
  setShowOrderDetails: Dispatch<SetStateAction<boolean>>;
  setShowVendorForm: Dispatch<SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation();

  const { deleteOneOrder, deletingOneOrder } = useOrderMutations();

  const { refund } = useStripeCheckoutSessionMutations();
  const customerActions = (row: Order) => {
    const handleEditOrder = () => {
      setOrder(row);
      setShowForm(true);
    };
    const handleDelete = () => {
      if (row?.id) {
        deleteOneOrder({ variables: { input: { id: row.id } } });
      }
    };
    const handleSelect = (selected: string) => {
      setOrder(row);
      switch (selected) {
        case "UPDATE":
          handleEditOrder();
          break;
        case "DELETE":
          handleDelete();
          break;
        case "PAY":
          setShowPayzenForm(true);
          break;
        case "REFUND":
          refund({
            variables: {
              idOrder: row.id,
            },
          });
          break;
        case "DETAILS":
          setShowOrderDetails(true);
          break;
        case "RELAUNCH_ORDER":
          setShowVendorForm(true);
          break;
      }
    };
    const menus = [
      {
        label: t("commons.edit"),
        value: "UPDATE",
        disabled: [
          ORDER_STATUSES.NOT_VERIFIED,
          ORDER_STATUSES.DELIVERED,
          ORDER_STATUSES.UNPAID,
          ORDER_STATUSES.PAID,
          ORDER_STATUSES.REFUNDED,
          ORDER_STATUSES.READY,
          ORDER_STATUSES.CANCELED,
        ].includes(row.status),
      },
      {
        label: t("commons.delete"),
        value: "DELETE",
        disabled:
          [
            ORDER_STATUSES.NOT_VERIFIED,
            ORDER_STATUSES.DELIVERED,
            ORDER_STATUSES.UNPAID,
            ORDER_STATUSES.PAID,
            ORDER_STATUSES.REFUNDED,
            ORDER_STATUSES.READY,
          ].includes(row.status) || deletingOneOrder.loading,
      },
      {
        label: t("order.list.action.makeThePayment"),
        value: "PAY",
        disabled: row.status !== ORDER_STATUSES.UNPAID,
      },
      {
        label: t("order.list.action.askForARefund"),
        value: "REFUND",
        disabled: row.status !== ORDER_STATUSES.PAID,
      },
      {
        label: t("order.list.action.rerunOrder"),
        value: "RELAUNCH_ORDER",
        disabled: row.status != ORDER_STATUSES.CANCELED,
      },
      {
        label: t("commons.seeDetails"),
        value: "DETAILS",
      },
    ];

    return <MoreHorizMenu items={menus} onSelect={handleSelect} />;
  };

  const customerColumns: Column[] = isCustomer
    ? [
        {
          label: t("order.list.label.deliveryCode"),
          name: "deliveryCode",
          breaking: true,
          sortable: true,
        },
        {
          label: t("order.list.label.totalPrice"),
          name: "totalPrice",
          renderer: (row: Order) => {
            return `${Number(
              row.vendorPrice + row.deliveryPrice + row.totalPrice
            ).toFixed(2)}€ ${row.reduction ? `(-${row.reduction}%)` : ''}`;
          },
        },
      ]
    : [];
  return { customerColumns, customerActions };
};
