import { FormWrapper } from "@components/Form/Form";
import { Modal } from "@components/Modal";
import { Order } from "@graphql/";
import { InputProps } from "@src/types";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

interface OrderDeliveryCodeModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  order?: Order;
  onVerifyDeliveryCode: (deliveryCode: string) => void;
  loading: boolean;
}

export const OrderDeliveryCodeModal: FC<OrderDeliveryCodeModalProps> = ({
  open,
  setOpen,
  order,
  onVerifyDeliveryCode,
  loading,
}) => {
  const { t } = useTranslation();
  const initialCreateInput = {
    deliveryCode: "",
  };
  const schema = yup
    .object({
      deliveryCode: yup
        .string()
        .required(t("order.list.deliveryCode.errors.deliveryCodeRequired")),
    })
    .required();

  const handleVerifyDeliveryCode = (state: any) => {
    onVerifyDeliveryCode(state.deliveryCode);
  };

  const inputs: InputProps[] = [
    {
      name: "deliveryCode",
      label: t("order.list.deliveryCode.label.deliveryCode"),
      type: "text",
      placeholder: t("order.list.deliveryCode.label.deliveryCode"),
    },
  ];

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      maxWidth="md"
      title={`${t("order.list.deliveryCode.title")} ${order?.ref}`}
      onClose={handleClose}
    >
      <FormWrapper
        initialCreateInput={initialCreateInput}
        onSubmit={handleVerifyDeliveryCode}
        onCancel={handleClose}
        schema={schema}
        loading={loading}
        submitBtnLabel={t("order.list.deliveryCode.verifyTheCode")}
        inputs={inputs}
      />
    </Modal>
  );
};
