import { useTranslation } from "react-i18next";
import {
  SupportTicket,
  useOrders_RefsQuery,
  useVerify_TokenLazyQuery,
} from "@graphql/";
import { useApplicationContext } from "@src/context";
import { useFilesMutations, useSupportTicketsMutations } from "@src/hooks";
import React, { FC, useState } from "react";
import { Modal } from "@components/Modal";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { FileUploader } from "react-drag-drop-files";
import { SupportTicketOrderForm } from "./SupportTicketOrderForm";
import { SupportTicketOthersForm } from "./SupportTicketOthersForm";
import { useApolloClient } from "@apollo/client";

interface SupportTicketFormProps {
  open: boolean;
  onClose: () => void;
  supportTicket?: SupportTicket;
}

export const SupportTicketForm: FC<SupportTicketFormProps> = ({
  open,
  onClose,
  supportTicket,
}) => {
  const { t } = useTranslation();
  const [category, setCategory] = useState<"ORDER" | "OTHERS">(
    (supportTicket?.category as "ORDER" | "OTHERS") || "ORDER"
  );
  const [priority, setPriority] = useState<"LOW" | "MEDIUM" | "HIGH">(
    (supportTicket?.priority as "LOW" | "MEDIUM" | "HIGH") || "LOW"
  );
  const [isCodeValid, setIsCodeValid] = useState<boolean>(false);
  const [verificationCode, setVerificationCode] = useState<string>("");
  const [customerId, setCustomerId] = useState<string>();
  const [file, setFile] = useState<File>();
  const { uploadFiles, isUploading } = useFilesMutations();
  const client = useApolloClient();

  const { connectedUser, connectedUserType, notify } = useApplicationContext();
  const [verifyToken] = useVerify_TokenLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data.verifyToken.id) {
        setCustomerId(data.verifyToken.id);
        setIsCodeValid(true);
      }
    },
    onError: (e) => {
      notify({
        message: e.message,
        type: "error",
      });
    },
  });
  const { createOneSupportTicket, updateOneSupportTicket, loading } =
    useSupportTicketsMutations(file === undefined);
  const ordersQuery = useOrders_RefsQuery({
    fetchPolicy: "network-only",
    variables: {
      filter: {
        idCustomer: {
          eq:
            connectedUserType === "SERVICE_CUSTOMER"
              ? customerId
              : connectedUser?.id,
        },
      },
    },
    skip:
      !connectedUser ||
      (connectedUserType === "SERVICE_CUSTOMER" && !customerId),
  });
  const orders = ordersQuery.data?.orders.nodes || [];

  const fileTypes = ["JPEG", "PNG", "PDF", "JPG"];

  const categoriesSelectOptions = [
    {
      label: "Commande",
      value: "ORDER",
    },
    {
      label: "Autres",
      value: "OTHERS",
    },
  ];

  const prioritiesSelectOptions = [
    {
      label: "Basse",
      value: "LOW",
    },
    {
      label: "Moyenne",
      value: "MEDIUM",
    },
    {
      label: "Haute",
      value: "HIGH",
    },
  ];

  const handleSubmit = async (state: any, id?: string) => {
    if (id) {
      updateOneSupportTicket({
        variables: {
          input: {
            id,
            update: {
              category: category,
              priority: priority,
              subject: state.subject,
              description: state.description,
              idOrder: state.idOrder,
            },
          },
        },
      }).then(async ({ data }) => {
        const idSupportTicket = data?.updateOneSupportTicket.id;
        if (idSupportTicket) {
          await handleFileUpload(idSupportTicket);
        }
        onClose();
      });
    } else {
      createOneSupportTicket({
        variables: {
          input: {
            supportTicket: {
              ...state,
              category: category,
              priority: priority,
              idCustomer:
                connectedUserType === "SERVICE_CUSTOMER"
                  ? customerId
                  : connectedUser.id,
              idServiceCustomer:
                connectedUserType === "SERVICE_CUSTOMER"
                  ? connectedUser.id
                  : undefined,
            },
          },
        },
      }).then(async ({ data }) => {
        const idSupportTicket = data?.createOneSupportTicket.id;
        if (idSupportTicket) {
          await handleFileUpload(idSupportTicket);
        }
        onClose();
      });
    }
  };

  const handleFileUpload = async (idSupportTicket: string) => {
    if (file) {
      await uploadFiles([
        {
          file: file,
          name: "SUPPORT_TICKET_DOCUMENT",
          filename: file?.name || "",
          type: "SUPPORT_TICKET",
          idType: idSupportTicket,
          idUser: connectedUser.id,
        },
      ]);
      await client.refetchQueries({
        include: ["SUPPORT_TICKETS", "SUPPORT_TICKETS_COUNTS"],
      });
    }
  };

  const handleCategoryChange = (e: SelectChangeEvent<"ORDER" | "OTHERS">) => {
    setCategory(e.target.value as "ORDER" | "OTHERS");
  };

  const handlePriorityChange = (
    e: SelectChangeEvent<"LOW" | "MEDIUM" | "HIGH">
  ) => {
    setPriority(e.target.value as "LOW" | "MEDIUM" | "HIGH");
  };

  const handleFileUploaderChange = (file: File) => {
    setFile(file);
  };

  const handleVerifyCode = async () => {
    await verifyToken({
      variables: { action: "USER", token: verificationCode },
    });
  };

  return (
    <Modal
      title={
        supportTicket
          ? t("supportTicket.form.title.update")
          : t("supportTicket.form.title.create")
      }
      open={open}
      maxWidth="md"
      onClose={onClose}
    >
      {!isCodeValid && connectedUserType === "SERVICE_CUSTOMER" ? (
        <Box sx={{ minWidth: { xs: "unset", sm: 600 } }}>
          <Typography sx={{ mb: 1 }}>
            {t("supportTicket.form.verification.subtitle")}:
          </Typography>
          <TextField
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            label="Code de vérification"
            variant="outlined"
            fullWidth
            sx={{ mb: 2 }}
          />
          <Box justifyContent="flex-end" display="flex">
            <Button
              color="error"
              variant="contained"
              sx={{ mr: 1 }}
              onClick={onClose}
            >
              {t("commons.cancel")}
            </Button>
            <Button
              color="primary"
              variant="contained"
              disabled={verificationCode === ""}
              onClick={handleVerifyCode}
            >
              {t("supportTicket.form.verification.verify")}
            </Button>
          </Box>
        </Box>
      ) : (
        <Box>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel> {t("commons.category")}</InputLabel>
            <Select value={category} onChange={handleCategoryChange}>
              {categoriesSelectOptions.map((option) => (
                <MenuItem
                  key={`select-category-${option.value}`}
                  value={option.value}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>{t("supportTicket.form.priority")}</InputLabel>
            <Select value={priority} onChange={handlePriorityChange}>
              {prioritiesSelectOptions.map((option) => (
                <MenuItem
                  key={`select-prioriy-${option.value}`}
                  value={option.value}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box sx={{ mb: 2 }}>
            <FileUploader
              multiple={false}
              label="Télécharger ou déposer une pièce jointe ici"
              handleChange={handleFileUploaderChange}
              types={fileTypes}
            />
          </Box>
          {category === "ORDER" ? (
            <SupportTicketOrderForm
              onSubmit={handleSubmit}
              onCancel={onClose}
              orders={orders}
              loading={loading || isUploading}
            />
          ) : (
            <SupportTicketOthersForm
              onSubmit={handleSubmit}
              onCancel={onClose}
              loading={loading || isUploading}
            />
          )}
        </Box>
      )}
    </Modal>
  );
};
