import { useUpdate_Many_NotificationsMutation } from "@graphql/";
import { useApplicationContext } from "@src/context";
import { useTranslation } from "react-i18next";
export const useNotificationsMutations = () => {
  const { t } = useTranslation();
  const { notify } = useApplicationContext();
  const [updateManyNotifications] = useUpdate_Many_NotificationsMutation({
    refetchQueries: ["NOTIFICATIONS", "NOTIFICATIONS_COUNT"],
    onError: () => {
      notify({
        message: t("commons.errorOccurred"),
        type: "error",
      });
    },
  });

  return { updateManyNotifications };
};
