import { gql } from "@apollo/client";

export const PENDING_ACTIONS_QUERY = gql`
  query PendingActionsQuery {
    pendingActions @client {
      id
      type
      address
      vendor
      datetime
      createdAt
      variables
    }
  }
`;
