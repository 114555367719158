import { DeleteIconBtn } from "@components/DeleteIconBtn";
import { Form } from "@components/Form/Form";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { DateClickArg } from "@fullcalendar/interaction";
import FullCalendar, { EventContentArg } from "@fullcalendar/react";
import { useTarificationsQuery } from "@graphql/";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button
} from "@mui/material";
import { InputProps } from "@src/types";
import moment from "moment";
import React, { FC, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useTarificationMutations } from "./useTarificationMutations";
import { Modal } from "@components/Modal";
import { useTranslation } from "react-i18next";

interface CalendarProps {}

export const Calendar: FC<CalendarProps> = ({}) => {
  const { t } = useTranslation();

  const initialCreateInput = {
    dateDebut: null,
    dateFin: null,
    tarifMinimum: 0,
    prixKmJour: 0,
    prixKmSoir: 0,
    tva: 0,
    deliveryManPercentage: 10,
  };

  const schema = yup
    .object({
      dateDebut: yup
        .date()
        .min(
          moment().subtract(1, "d").toDate(),
          t("tarifications.errors.chooseEarlierStartDate")
        )
        .required(t("tarifications.errors.startDateRequired")),
      dateFin: yup
        .date()
        .min(new Date(), t("tarifications.errors.chooseEarlierStartDate"))
        .required(),
      tarifMinimum: yup
        .number()
        .positive(t("tarifications.errors.negativeMinTarif"))
        .required(t("tarifications.errors.requiredTarif")),
      prixKmJour: yup
        .number()
        .positive(t("tarifications.errors.negativePriceKmJ"))
        .required(t("tarifications.errors.requiredPriceKmJ")),
      prixKmSoir: yup
        .number()
        .positive(t("tarifications.errors.negativePriceKmS"))
        .required(t("tarifications.errors.requiredPriceKmS")),
      tva: yup
        .number()
        .positive(t("tarifications.errors.negativeTva"))
        .required(t("tarifications.errors.requiredTva")),
      deliveryManPercentage: yup
        .number()
        .positive(t("tarifications.errors.negativePercentageLivreur"))
        .required(t("tarifications.errors.requiredPercentageLivreur")),
    })
    .required();

  const {
    control,
     formState: { errors },
    setValue,
    handleSubmit,
    reset,
    getValues,
  } = useForm<any>({
    defaultValues: initialCreateInput,
    resolver: yupResolver(schema),
  });
  const [showDateClickModal, setShowDateClickModal] = useState<boolean>(false);
  const tarificationsQuery = useTarificationsQuery();
  const tarifications = (
    tarificationsQuery.data?.tarifications.nodes || []
  ).map((tarification) => ({
    ...tarification,
    start: tarification.dateDebut,
    end: tarification.dateFin,
  }));

  const tarificationInputs: InputProps[] = [
    {
      name: "dateDebut",
      label: t("tarifications.startDate"),
      type: "date",
      placeholder: t("tarifications.startDate"),
      required: true,
    },
    {
      name: "dateFin",
      label: t("tarifications.endDate"),
      type: "date",
      placeholder: t("tarifications.endDate"),
      required: true,
    },
    {
      name: "tarifMinimum",
      label: t("tarifications.min"),
      type: "number",
      placeholder: t("tarifications.min"),
      min: 0,
      required: true,
      textafter: "€",
    },
    {
      name: "prixKmJour",
      label: t("tarifications.priceKmj"),
      type: "number",
      placeholder: t("tarifications.priceKmInJourney"),
      min: 0,
      required: true,
      textafter: "€",
    },
    {
      name: "prixKmSoir",
      label: t("tarifications.priceKms"),
      type: "number",
      placeholder: t("tarifications.priceKmInEvening"),
      min: 0,
      required: true,
      textafter: "€",
    },
    {
      name: "tva",
      label: t("tarifications.vta"),
      type: "number",
      placeholder: t("tarifications.vta"),
      min: 0,
      required: true,
      textafter: "%",
    },
    {
      name: "deliveryManPercentage",
      label: t("tarifications.deliveryPercentage"),
      type: "number",
      placeholder: t("tarifications.deliveryPercentage"),
      min: 0,
      required: true,
      textafter: "%",
    },
  ];

  const {
    createOneTarification,
    deleteOneTarification,
    updateOneTarification,
    deletingOneTarification,
    loading,
  } = useTarificationMutations();

  const toggleShowConfirmDialog = () => {
    setShowDateClickModal(false);
  };
  const handleDateClick = (arg: DateClickArg) => {
    if (moment(arg.date).isBefore(moment(), "d")) {
      return;
    }
    const tarification = tarifications.find((tarification) => {
      const dateFin = new Date(tarification.dateFin);
      const dateDebut = new Date(tarification.dateDebut);
      return (
        arg.date.getDate() <= dateFin.getDate() &&
        arg.date.getDate() >= dateDebut.getDate()
      );
    });

    if (tarification) {
      reset({
        id: tarification.id,
        tva: tarification.tva,
        prixKmJour: tarification.prixKmJour,
        prixKmSoir: tarification.prixKmSoir,
        dateDebut: new Date(tarification.dateDebut).toISOString().split("T")[0],
        dateFin: new Date(tarification.dateFin).toISOString().split("T")[0],
        tarifMinimum: tarification.tarifMinimum,
        deliveryManPercentage: tarification.deliveryManPercentage,
      });
    } else {
      reset(initialCreateInput);
    }
    setShowDateClickModal(true);
  };

  const CalendarEventContent = (eventInfo: EventContentArg) => {
    const event = eventInfo.event.toJSON();
    const tarification = event.extendedProps;
    return (
      <div
        onClick={() => {
          reset({
            id: event.id,
            tva: tarification.tva,
            prixKmJour: tarification.prixKmJour,
            prixKmSoir: tarification.prixKmSoir,
            dateDebut: new Date(tarification.dateDebut)
              .toISOString()
              .split("T")[0],
            dateFin: new Date(tarification.dateFin).toISOString().split("T")[0],
            tarifMinimum: tarification.tarifMinimum,
            deliveryManPercentage: tarification.deliveryManPercentage,
          });
          setShowDateClickModal(true);
        }}
      >
        {tarification.tarifMinimum}€
      </div>
    );
  };

  const onSubmit = (state: any) => {
    if (state.id) {
      updateOneTarification({
        variables: {
          input: {
            update: {
              ...state,
              dateDebut: state.dateDebut,
              dateFin: state.dateFin,
              id: undefined,
            },
            id: state.id,
          },
        },
      }).then(() => {
        afterSubmit();
      });
    } else {
      createOneTarification({
        variables: {
          input: {
            tarification: {
              ...state,
              dateDebut: state.dateDebut,
              dateFin: state.dateFin,
            },
          },
        },
      }).then(() => {
        afterSubmit();
      });
    }
  };

  const onDelete = () => {
    deleteOneTarification({
      variables: { input: { id: getValues().id } },
    }).then(() => {
      afterSubmit();
    });
  };

  const afterSubmit = () => {
    setShowDateClickModal(false);
  };

  return (
    <>
      <Modal
        open={showDateClickModal}
        maxWidth="xs"
        onClose={toggleShowConfirmDialog}
        title="Tarification"
        actions={
          <>
            <DeleteIconBtn
              loading={loading}
              onDelete={onDelete}
              deleted={deletingOneTarification.data?.deleteOneTarification.id}
            />
            <Button disabled={loading} onClick={toggleShowConfirmDialog}>
              {t("commons.close")}
            </Button>
            <Button
              color="primary"
              onClick={handleSubmit((state) => onSubmit(state))}
              disabled={loading}
            >
              {t("commons.save")}
            </Button>
          </>
        }
      >
        <Form
          control={control}
          errors={errors}
          inputs={tarificationInputs}
          setValue={setValue}
        />
      </Modal>
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        dateClick={handleDateClick}
        eventContent={CalendarEventContent}
        events={tarifications}
        timeZone="locale"
      />
    </>
  );
};
