import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Create_One_Support_TicketMutationVariables = Types.Exact<{
  input: Types.CreateOneSupportTicketInput;
}>;


export type Create_One_Support_TicketMutation = { __typename?: 'Mutation', createOneSupportTicket: { __typename?: 'SupportTicket', id: string } };

export type Update_One_Support_TicketMutationVariables = Types.Exact<{
  input: Types.UpdateOneSupportTicketInput;
}>;


export type Update_One_Support_TicketMutation = { __typename?: 'Mutation', updateOneSupportTicket: { __typename?: 'SupportTicket', id: string } };

export type Send_Support_Tikcet_Resolution_RequestMutationVariables = Types.Exact<{
  idSupportTicket: Types.Scalars['String'];
}>;


export type Send_Support_Tikcet_Resolution_RequestMutation = { __typename?: 'Mutation', sendSupportTicketResolutionRequest: boolean };


export const Create_One_Support_TicketDocument = gql`
    mutation CREATE_ONE_SUPPORT_TICKET($input: CreateOneSupportTicketInput!) {
  createOneSupportTicket(input: $input) {
    id
  }
}
    `;
export type Create_One_Support_TicketMutationFn = Apollo.MutationFunction<Create_One_Support_TicketMutation, Create_One_Support_TicketMutationVariables>;

/**
 * __useCreate_One_Support_TicketMutation__
 *
 * To run a mutation, you first call `useCreate_One_Support_TicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreate_One_Support_TicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneSupportTicketMutation, { data, loading, error }] = useCreate_One_Support_TicketMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreate_One_Support_TicketMutation(baseOptions?: Apollo.MutationHookOptions<Create_One_Support_TicketMutation, Create_One_Support_TicketMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Create_One_Support_TicketMutation, Create_One_Support_TicketMutationVariables>(Create_One_Support_TicketDocument, options);
      }
export type Create_One_Support_TicketMutationHookResult = ReturnType<typeof useCreate_One_Support_TicketMutation>;
export type Create_One_Support_TicketMutationResult = Apollo.MutationResult<Create_One_Support_TicketMutation>;
export type Create_One_Support_TicketMutationOptions = Apollo.BaseMutationOptions<Create_One_Support_TicketMutation, Create_One_Support_TicketMutationVariables>;
export const Update_One_Support_TicketDocument = gql`
    mutation UPDATE_ONE_SUPPORT_TICKET($input: UpdateOneSupportTicketInput!) {
  updateOneSupportTicket(input: $input) {
    id
  }
}
    `;
export type Update_One_Support_TicketMutationFn = Apollo.MutationFunction<Update_One_Support_TicketMutation, Update_One_Support_TicketMutationVariables>;

/**
 * __useUpdate_One_Support_TicketMutation__
 *
 * To run a mutation, you first call `useUpdate_One_Support_TicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_One_Support_TicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneSupportTicketMutation, { data, loading, error }] = useUpdate_One_Support_TicketMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdate_One_Support_TicketMutation(baseOptions?: Apollo.MutationHookOptions<Update_One_Support_TicketMutation, Update_One_Support_TicketMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Update_One_Support_TicketMutation, Update_One_Support_TicketMutationVariables>(Update_One_Support_TicketDocument, options);
      }
export type Update_One_Support_TicketMutationHookResult = ReturnType<typeof useUpdate_One_Support_TicketMutation>;
export type Update_One_Support_TicketMutationResult = Apollo.MutationResult<Update_One_Support_TicketMutation>;
export type Update_One_Support_TicketMutationOptions = Apollo.BaseMutationOptions<Update_One_Support_TicketMutation, Update_One_Support_TicketMutationVariables>;
export const Send_Support_Tikcet_Resolution_RequestDocument = gql`
    mutation SEND_SUPPORT_TIKCET_RESOLUTION_REQUEST($idSupportTicket: String!) {
  sendSupportTicketResolutionRequest(idSupportTicket: $idSupportTicket)
}
    `;
export type Send_Support_Tikcet_Resolution_RequestMutationFn = Apollo.MutationFunction<Send_Support_Tikcet_Resolution_RequestMutation, Send_Support_Tikcet_Resolution_RequestMutationVariables>;

/**
 * __useSend_Support_Tikcet_Resolution_RequestMutation__
 *
 * To run a mutation, you first call `useSend_Support_Tikcet_Resolution_RequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSend_Support_Tikcet_Resolution_RequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendSupportTikcetResolutionRequestMutation, { data, loading, error }] = useSend_Support_Tikcet_Resolution_RequestMutation({
 *   variables: {
 *      idSupportTicket: // value for 'idSupportTicket'
 *   },
 * });
 */
export function useSend_Support_Tikcet_Resolution_RequestMutation(baseOptions?: Apollo.MutationHookOptions<Send_Support_Tikcet_Resolution_RequestMutation, Send_Support_Tikcet_Resolution_RequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Send_Support_Tikcet_Resolution_RequestMutation, Send_Support_Tikcet_Resolution_RequestMutationVariables>(Send_Support_Tikcet_Resolution_RequestDocument, options);
      }
export type Send_Support_Tikcet_Resolution_RequestMutationHookResult = ReturnType<typeof useSend_Support_Tikcet_Resolution_RequestMutation>;
export type Send_Support_Tikcet_Resolution_RequestMutationResult = Apollo.MutationResult<Send_Support_Tikcet_Resolution_RequestMutation>;
export type Send_Support_Tikcet_Resolution_RequestMutationOptions = Apollo.BaseMutationOptions<Send_Support_Tikcet_Resolution_RequestMutation, Send_Support_Tikcet_Resolution_RequestMutationVariables>;