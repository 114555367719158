import { BackdropLoader } from "@components/BackdropLoader";
import { MessageBox } from "@components/MessageBox";
import { useVerify_TokenQuery } from "@graphql/";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

type State = "LOADING" | "VALIDATING_TOKEN" | "INVALID_TOKEN" | "UPDATED";
export const SupportTicketResolution: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const token = query.get("token");
  const action = query.get("action");
  const [state, setState] = useState<State>("VALIDATING_TOKEN");

  useVerify_TokenQuery({
    fetchPolicy: "network-only",
    skip: !token || !action,
    variables: {
      token: token || "",
      action: action || "",
    },
    onCompleted: () => {
      setState("UPDATED");
    },
    onError: () => {
      setState("INVALID_TOKEN");
    },
  });

  const handleGoToMyTickets = () => {
    navigate("/dashboard/my-tickets?status=ALL_TICKETS&collapsed=ALL_TICKETS");
  };

  const renders = {
    LOADING: "",
    INVALID_TOKEN: (
      <MessageBox message={t("accountVerification.invalidToken")} />
    ),
    VALIDATING_TOKEN: (
      <MessageBox message={t("supportTicket.resolution.updating")} />
    ),
    UPDATED: (
      <MessageBox
        message={t("supportTicket.resolution.updated")}
        actionBtn={{
          onClick: handleGoToMyTickets,
          children: t("supportTicket.resolution.label.goToMyTickets"),
        }}
      />
    ),
  };

  return (
    <>
      <BackdropLoader open={state === "LOADING"} />
      {renders[state]}
    </>
  );
};
