import { Modal } from "@components/Modal";
import {
  Order,
  useAvailable_Delivery_MansQuery,
  useSend_Application_SupportMutation,
} from "@graphql/";
import {
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { FC, useState } from "react";

export interface AvailableDeliveryMansProps {
  open: boolean;
  onClose: () => void;
  order: Order;
}

export const AvailableDeliveryMans: FC<AvailableDeliveryMansProps> = ({
  order,
  open,
  onClose,
}) => {
  const [assigned, setAssigned] = useState<string[]>([]);
  const [sendApplicationSupport] = useSend_Application_SupportMutation();

  const availableDeliveryMansQuery = useAvailable_Delivery_MansQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      idOrder: order.id,
    },
  });
  const availableDeliveryMans =
    availableDeliveryMansQuery.data?.availableDeliveryMans || [];

  const orderAssignments = order.orderAssignments || [];

  const handleAssign = (idDeliveryMan: string) => {
    sendApplicationSupport({
      variables: { idDeliveryMan, idOrder: order.id },
      refetchQueries: ["ORDERS"],
    }).then(() => {
      setAssigned((prev) => [...prev, idDeliveryMan]);
    });
  };

  const isAssignedStatus = (idDeliveryMan: string) => {
    return orderAssignments.find(
      (orderAssignment) => orderAssignment.idDeliveryMan === idDeliveryMan
    )?.status;
  };

  return (
    <Modal
      open={open}
      maxWidth="xl"
      title={`${t()} ${order?.ref}`}
      onClose={onClose}
    >
      {availableDeliveryMans.length ? (
        <List>
          {availableDeliveryMans.map((deliveryMan) => {
            const status = isAssignedStatus(deliveryMan.id);
            return (
              <ListItem
                secondaryAction={
                  <Button
                    onClick={() => handleAssign(deliveryMan.id)}
                    disabled={
                      status ? ["REJECTED", "ACCEPTED"].includes(status) : false
                    }
                  >
                    {status === "PENDING"
                      ? "Renvoyer"
                      : status === "REJECTED"
                      ? "Refusé"
                      : status === "ACCEPTED"
                      ? "Accepté"
                      : "Envoyer"}
                  </Button>
                }
              >
                <ListItemText
                  primary={`${deliveryMan.firstName} ${deliveryMan.lastName}`}
                />
              </ListItem>
            );
          })}
        </List>
      ) : (
        <Typography>
          Il n'y a pas de livreur disponible et proche de la pharmacie pour le
          moment
        </Typography>
      )}
    </Modal>
  );
};
