import {
  useCreate_One_Order_ItemMutation,
  useDelete_One_Order_ItemMutation,
  useUpdate_One_Order_ItemMutation,
} from "@graphql/";
import { useApplicationContext } from "@src/context";
import { useTranslation } from "react-i18next";
export const useOrderItemMutations = () => {
  const { t } = useTranslation();
  const { notify } = useApplicationContext();
  const [createOneOrderItem] = useCreate_One_Order_ItemMutation({
    refetchQueries: ["ORDERS", "ORDER_ITEMS"],
    onCompleted: () => {
      notify({
        message: t("orderItem.form.success.create"),
        type: "success",
      });
    },
    onError: () => {
      notify({
        message: t("commons.errorOccurred"),
        type: "error",
      });
    },
  });

  const [updateOneOrderItem] = useUpdate_One_Order_ItemMutation({
    refetchQueries: ["ORDERS", "ORDER_ITEMS"],
    onCompleted: () => {
      notify({
        message: t("orderItem.form.success.update"),
        type: "success",
      });
    },
    onError: () => {
      notify({
        message: t("commons.errorOccurred"),
        type: "error",
      });
    },
  });

  const [deleteOneOrderItem, deletingOneOrderItem] =
    useDelete_One_Order_ItemMutation({
      refetchQueries: ["ORDERS", "ORDER_ITEMS"],
      onCompleted: (data) => {
        notify({
          message: t("orderItem.form.success.delete"),
          type: "success",
        });
      },
      onError: () => {
        notify({
          message: t("commons.errorOccurred"),
          type: "error",
        });
      },
    });

  return {
    createOneOrderItem,
    deleteOneOrderItem,
    updateOneOrderItem,
    deletingOneOrderItem,
  };
};
