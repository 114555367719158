import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { initI18n } from "./i18n/i18n";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  from,
  InMemoryCache,
} from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { onError } from "@apollo/client/link/error";
import { NotificationSnackBar } from "./lib";
import { Chart, registerables } from "chart.js";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { split } from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { persistCache, LocalStorageWrapper } from "apollo3-cache-persist";

Chart.register(...registerables);
initI18n();

const el = document.getElementById("root");
if (el) {
  const root = ReactDOM.createRoot(el);
  const errorLink = onError(({ graphQLErrors, operation, forward }) => {
    if (graphQLErrors) {
      graphQLErrors.map((graphQLError) => console.error(graphQLError.message));
    }

    forward(operation);
  });

  let token = localStorage.getItem("token");

  const wsLink = new GraphQLWsLink(
    createClient({
      url: `${process.env.WS_URL}/graphql`,
      connectionParams: () => {
        return new Promise<Record<string, unknown>>((resolve) => {
          token = localStorage.getItem("token");
          resolve({ authorization: `Bearer ${token}` });
        });
      },
      retryAttempts: 5,
      shouldRetry: (errOrCloseEvent) => {
        return (
          errOrCloseEvent instanceof CloseEvent &&
          errOrCloseEvent.reason === "Forbidden"
        );
      },
    })
  );

  const httpLink = createUploadLink({ uri: `${process.env.API_URL}/graphql` });

  const authLink = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers }: { headers: Headers }) => ({
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
        ...headers,
      },
    }));
    return forward(operation);
  });

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    authLink.concat(httpLink)
  );

  const cache = new InMemoryCache({ typePolicies: { Order: { merge: true } } });
  const client = new ApolloClient({
    cache,
    link: from([splitLink, errorLink]),
  });

  persistCache({
    cache,
    storage: new LocalStorageWrapper(window.localStorage),
    trigger: "write", // Persist only on write events
    key: "apollo-cache-pending-actions",
    debug: true,
  });

  root.render(
    <ApolloProvider client={client}>
      <NotificationSnackBar />
      <React.StrictMode>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </React.StrictMode>
    </ApolloProvider>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
