import { useApolloClient } from "@apollo/client";
import {
  useCreate_One_OrderMutation,
  useUpdate_One_OrderMutation,
  useDelete_One_OrderMutation,
  useVerify_Delivery_CodeMutation,
} from "@graphql/";
import { useApplicationContext } from "@src/context";
import { ORDER_STATUSES } from "@utils/generatedConstants";
import { useTranslation } from "react-i18next";
export const useOrderMutations = () => {
  const { t } = useTranslation();
  const client = useApolloClient();
  const { notify } = useApplicationContext();
  const [createOneOrder, creatingOneOrder] = useCreate_One_OrderMutation({
    onCompleted: () => {
      client.refetchQueries({
        include: ["ORDERS", "ORDER_STATUSES_COUNTS", "VERIFY_BEARER_TOKEN"],
      });
      notify({
        message: t("order.form.success.create"),
        type: "success",
      });
    },
    onError: () => {
      notify({
        message: t("order.form.error"),
        type: "error",
      });
    },
  });

  const [updateOneOrder, updatingOneOrder] = useUpdate_One_OrderMutation({
    onCompleted: () => {
      client.refetchQueries({
        include: ["ORDERS", "ORDER_STATUSES_COUNTS", "VERIFY_BEARER_TOKEN"],
      });
      notify({
        message: t("order.form.success.update"),
        type: "success",
      });
    },
    onError: () => {
      notify({
        message: t("order.form.error"),
        type: "error",
      });
    },
  });

  const [deleteOneOrder, deletingOneOrder] = useDelete_One_OrderMutation({
    refetchQueries: ["ORDERS"],
    onCompleted: (data) => {
      client.refetchQueries({
        include: ["ORDERS", "ORDER_STATUSES_COUNTS", "VERIFY_BEARER_TOKEN"],
      });
      notify({
        message: t("order.form.success.delete"),
        type: "success",
      });
    },
    onError: () => {
      notify({
        message: t("order.form.error"),
        type: "error",
      });
    },
  });

  const [verifyDeliveryCode, verifyingDeliveryCode] =
    useVerify_Delivery_CodeMutation({
      refetchQueries: ["ORDERS"],
      onCompleted: (data) => {
        const order = data.verifyDeliveryCode;
        if (order) {
          notify({
            message: t("order.list.deliveryCode.valid"),
            type: "success",
          });
          updateOneOrder({
            variables: {
              input: {
                id: order.id,
                update: { status: ORDER_STATUSES.DELIVERED },
              },
            },
          });
        }
      },
      onError: () => {
        notify({
          message: t("order.list.deliveryCode.errors.invalidDeliveryCode"),
          type: "error",
        });
      },
    });

  const loading =
    creatingOneOrder.loading ||
    updatingOneOrder.loading ||
    deletingOneOrder.loading;

  return {
    createOneOrder,
    updateOneOrder,
    deleteOneOrder,
    verifyDeliveryCode,
    verifyingDeliveryCode,
    loading,
    deletingOneOrder,
  };
};
