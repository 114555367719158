import { Distance } from "@components/Distance";
import {
  Order,
  useCreate_One_User_HistoryMutation,
  useUpdate_One_Order_AssignmentMutation,
} from "@graphql/";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";
import React, { ChangeEvent, FC, useState } from "react";
import moment from "moment";
import { useOrderMutations } from "@components/CreateOrderStepper/useOrderMutations";
import { useApplicationContext } from "@src/context";
import { OrderAssignmentRejectForm } from "@components/OrderAssignmentRejectForm";
import { Modal } from "@components/Modal";
import { ORDER_STATUSES } from "@utils/constants";

interface DeliveryDetailsProps {
  show: boolean;
  onClose: () => void;
  order?: Order;
  isApplicationSupport?: boolean;
  idOrderAssignment?: string | null;
}

const Line = ({ label, value }: { label: string; value?: string }) => {
  return (
    <Box sx={{ display: "flex", mb: 3 }}>
      <Typography mr={1} fontWeight="bold">
        {label}:{" "}
      </Typography>
      <Typography>{value}</Typography>
    </Box>
  );
};

export const DeliveryDetails: FC<DeliveryDetailsProps> = ({
  order,
  show,
  onClose,
  isApplicationSupport,
  idOrderAssignment,
}) => {
  const { connectedUser, notify } = useApplicationContext();
  const { updateOneOrder } = useOrderMutations();
  const [showOrderAssignmentRejectForm, setShowOrderAssignmentRejectForm] =
    useState<boolean>(false);
  const [rejectMessage, setRejectMessage] = useState<string>("");
  const [createOneUserHistory] = useCreate_One_User_HistoryMutation({
    onCompleted: () => {
      notify({
        message: "Votre réponse a été enrégistré",
        type: "success",
      });
      onClose();
    },
  });
  const [updateOneOrderAssignment] = useUpdate_One_Order_AssignmentMutation();
  const clientLines = [
    {
      label: "Nom",
      value: order?.customer?.firstName,
    },
    {
      label: "Prénom",
      value: order?.customer?.lastName,
    },
    {
      label: "Addresse de livraison",
      value: `${order?.address?.name} ${order?.address?.city} ${order?.address?.zipcode}`,
    },
  ];

  const pharmacyLines = [
    {
      label: "Nom",
      value: order?.vendor?.infoLegale?.name,
    },
    {
      label: "Addresse de récupération",
      value: order?.vendor?.addresses
        ? `${order?.vendor?.addresses[0].name} ${order?.vendor?.addresses[0].city} ${order?.vendor?.addresses[0].zipcode}`
        : "",
    },
  ];

  const commandLines = [
    {
      label: "Date et heure de livraison",
      value: moment(order?.datetime).format("DD/MM/YYYY à HH:mm"),
    },
    {
      label: "Prix de la livraison",
      value: `${order?.deliveryPrice}€`,
    },
  ];

  const handleAcceptDelivery = () => {
    updateOneOrder({
      variables: {
        input: {
          id: order?.id as string,
          update: {
            status: ORDER_STATUSES.ASSIGNED,
            idDeliveryMan: connectedUser.id,
          },
        },
      },
    }).then(() => {
      if (idOrderAssignment) {
        updateOneOrderAssignment({
          variables: {
            input: {
              id: idOrderAssignment,
              update: {
                status: "ACCEPTED",
              },
            },
          },
        });
      }
      onClose();
    });
  };

  const handleRejectFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRejectMessage(e.target.value);
  };

  const handleRejectDelivery = () => {
    createOneUserHistory({
      variables: {
        input: {
          userHistory: {
            action: "REJECT_APPLICATION_SUPPORT",
            description: rejectMessage,
          },
        },
      },
    });
    if (idOrderAssignment) {
      updateOneOrderAssignment({
        variables: {
          input: {
            id: idOrderAssignment,
            update: {
              status: "REJECTED",
            },
          },
        },
      });
    }
    onClose();
  };

  return (
    <>
      <Modal
        open={show}
        maxWidth="xl"
        title={
          isApplicationSupport
            ? `Demande de prise en charge de la commande N° ${order?.ref}`
            : `Détails de la commande N° ${order?.ref}`
        }
        onClose={onClose}
        actions={
          isApplicationSupport && (
            <>
              <Button onClick={() => setShowOrderAssignmentRejectForm(true)}>
                Refuser
              </Button>
              <Button onClick={handleAcceptDelivery}>Accepter</Button>
            </>
          )
        }
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Card sx={{ height: "100%" }}>
              <CardHeader title="Commande" />
              <CardContent>
                {commandLines.map((line, index) => (
                  <Line {...line} key={`client-details-${index}`} />
                ))}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card sx={{ height: "100%" }}>
              <CardHeader title="Client" />
              <CardContent>
                {clientLines.map((line, index) => (
                  <Line {...line} key={`client-details-${index}`} />
                ))}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card sx={{ height: "100%" }}>
              <CardHeader title="Pharmacie" />
              <CardContent>
                {pharmacyLines.map((line, index) => (
                  <Line {...line} key={`pharmacy-details-${index}`} />
                ))}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Card>
          <CardContent>
            <Distance
              id="livraison-trajet"
              arrive={{
                lat: order?.address?.latitude || 0,
                lng: order?.address?.longitude || 0,
              }}
              depart={{
                lat:
                  (order?.vendor?.addresses &&
                    order?.vendor?.addresses[0].latitude) ||
                  0,
                lng:
                  (order?.vendor?.addresses &&
                    order?.vendor?.addresses[0].longitude) ||
                  0,
              }}
            />
          </CardContent>
        </Card>
      </Modal>
      {idOrderAssignment && (
        <OrderAssignmentRejectForm
          show={showOrderAssignmentRejectForm}
          idOrderAssignment={idOrderAssignment}
          onCancel={() => setShowOrderAssignmentRejectForm(false)}
          orderRef={order?.ref || ""}
          onClose={() => {
            onClose();
            setShowOrderAssignmentRejectForm(false);
          }}
        />
      )}
    </>
  );
};
