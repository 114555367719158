import { ChipSelect } from "@components/ChipSelect";
import { Column, Table } from "@components/Table";
import {
  NotificationSortFields,
  SortDirection,
  useNotificationsQuery,
  Notification,
  useUpdate_One_NotificationMutation,
} from "@graphql/";
import { Box, Tooltip, Typography } from "@mui/material";
import { useApplicationContext } from "@src/context";
import { usePagination } from "@src/hooks";
import { useNotificationIsDoneStatuses } from "@src/hooks/useNotificationIsDoneStatuses";
import moment from "moment";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

export const Urgents: FC = () => {
  const { connectedUser } = useApplicationContext();
  const { notificationIsDoneStatuses } = useNotificationIsDoneStatuses();
  const [updateNotification] = useUpdate_One_NotificationMutation();
  const { t } = useTranslation();
  const pagination = usePagination(false);
  const notificationsQuery = useNotificationsQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      filter: {
        type: { eq: "URGENT" },
      },
      sorting: [
        {
          direction: SortDirection.Desc,
          field: NotificationSortFields.CreatedAt,
        },
      ],
      paging: pagination.offsetPaging,
    },
    onCompleted: (data) => {
      pagination.setTotalCount(data.notifications.totalCount);
    },
    skip: !connectedUser,
  });

  const notifications = notificationsQuery.data?.notifications.nodes || [];

  const columns: Column[] = [
    {
      label: t("notification.list.label.notification"),
      name: "",
      renderer: (row: Notification) => {
        if (row.category === "BUG") {
          return (
            <Tooltip title={JSON.stringify(row.data)}>
              <Typography>{`Bug sur la mutation: '${row.action}'`}</Typography>
            </Tooltip>
          );
        }
        return (
          <Typography>
            {t(`notification.${row.category}.${row.action}`, row.data)}
          </Typography>
        );
      },
    },
    {
      label: t("notification.list.label.status"),
      name: "",
      renderer: (row: Notification) => {
        const handleChange = (value: string | boolean) => {
          updateNotification({
            refetchQueries: ["NOTIFICATIONS"],
            awaitRefetchQueries: true,
            variables: {
              input: { id: row.id, update: { isDone: value as boolean } },
            },
          });
        };
        return (
          <ChipSelect
            menuItems={notificationIsDoneStatuses}
            onChange={handleChange}
            value={row.isDone || false}
            width={100}
          />
        );
      },
    },
    {
      label: t("notification.list.label.createdAt"),
      name: "",
      renderer: (row: Notification) => {
        return moment(row.createdAt).format("DD/MM/YYYY à HH:mm");
      },
    },
  ];

  return (
    <Box>
      <Table
        title={t("serviceCustomer.urgents.title")}
        columns={columns}
        data={notifications}
        pagination={pagination}
      />
    </Box>
  );
};
