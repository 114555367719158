import { AvailableDeliveryMans } from "@components/AvailableDeliveryMans";
import { MoreHorizMenu } from "@components/MoreHorizMenu";
import { OrderDetails } from "@components/OrderDetails";
import { Column, Table } from "@components/Table";
import { Order, useOrdersQuery } from "@graphql/";
import { Typography, Box } from "@mui/material";
import { useApplicationContext } from "@src/context";
import { usePagination } from "@src/hooks";
import { ORDER_STATUSES } from "@utils/generatedConstants";
import moment from "moment";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";

export const UnassignedOrders: FC = () => {
  const { connectedUser } = useApplicationContext();
  const [showOrderDetails, setShowOrderDetails] = useState<boolean>(false);
  const [showAvailableDeliveryMans, setShowAvailableDeliveryMans] =
    useState<boolean>(false);
  const [order, setOrder] = useState<Order>();
  const pagination = usePagination(false);
  const { t } = useTranslation();
  const ordersQuery = useOrdersQuery({
    fetchPolicy: "network-only",
    variables: {
      filter: {
        updatedAt: {
          lt: moment().utc().subtract(10, "m").format(),
        },
        idDeliveryMan: {
          eq: null,
        },
        status: {
          eq: ORDER_STATUSES.READY,
        },
      },
      paging: pagination.offsetPaging,
    },
    onCompleted: (data) => {
      pagination.setTotalCount(data.orders.totalCount);
    },
    skip: !connectedUser,
  });
  const orders = ordersQuery.data?.orders.nodes || [];

  const columns: Column[] = [
    {
      label: t("order.list.label.ref"),
      name: "ref",
    },
    {
      label: t("order.list.label.dateTime"),
      name: "datetime",
      renderer: (row: Order) => {
        return moment(row.datetime).format("DD/MM/YYYY à HH:mm");
      },
      breaking: true,
    },
    {
      label: t("order.list.label.pharmacy"),
      name: "",
      renderer: (row: Order) => {
        return row.vendor?.infoLegale?.name?.split("(")[0];
      },
      breaking: true,
    },
    {
      label: t("order.list.label.deliveryAddress"),
      name: "address.name",
      renderer: (row: Order) => {
        return row.address?.name || t("commons.deletedAddress");
      },
      breaking: true,
    },
    {
      label: t("commons.actions"),
      name: "",
      renderer: (row: Order) => {
        const handleSelect = (selected: string) => {
          setOrder(row);
          switch (selected) {
            case "DETAILS":
              setShowOrderDetails(true);
              break;
            case "ASSIGN":
              setShowAvailableDeliveryMans(true);
              break;

            default:
              break;
          }
        };
        const menus = [
          {
            label: t("order.unassigned.actions.assignToADeliveryMan"),
            value: "ASSIGN",
          },
          {
            label: t("commons.seeDetails"),
            value: "DETAILS",
          },
        ];
        return <MoreHorizMenu items={menus} onSelect={handleSelect} />;
      },
    },
  ];

  return (
    <Box>
      <Table
        title={t("order.unassigned.title")}
        columns={columns}
        data={orders}
        pagination={pagination}
      />
      <OrderDetails
        show={showOrderDetails}
        onClose={() => {
          setOrder(undefined);
          setShowOrderDetails(false);
        }}
        order={order}
      />
      {order && (
        <AvailableDeliveryMans
          open={showAvailableDeliveryMans}
          onClose={() => {
            setOrder(undefined);
            setShowAvailableDeliveryMans(false);
          }}
          order={order}
        />
      )}
    </Box>
  );
};
