import React, { FC, useCallback, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import MuiAutocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import {
  ControllerRenderProps,
  FieldValues,
  UseFormSetValue,
} from "react-hook-form";
import { InputProps } from "@src/types";
import { debounce } from "@mui/material";

interface AutocompleteProps {
  options: { label: string }[];
  onSearch: (search: string) => void;
  field: ControllerRenderProps<FieldValues, string>;
  input?: InputProps;
  setValue?: UseFormSetValue<any>;
  disabled?: boolean;
}

export const Autocomplete: FC<AutocompleteProps> = ({
  options,
  onSearch,
  field,
  input,
  setValue,
  disabled,
}) => {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [debouncing, setDebouncing] = useState(false);
  const loading = debouncing;

  useEffect(() => {
    setDebouncing(true);
    if (inputValue.length > 2) {
      handleSearch(inputValue);
    } else {
      handleSearch("");
    }
  }, [inputValue]);

  const handleSearch = useCallback(
    debounce((inputValue) => {
      onSearch(inputValue);
      setDebouncing(false);
    }, 1500),
    []
  );

  return (
    <MuiAutocomplete
      disabled={disabled}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option.label === value.label}
      getOptionLabel={(option) => option.label}
      options={options}
      filterOptions={(options) => options}
      loading={loading}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      fullWidth
      onChange={(event, newValue) => {
        if (input?.setValues && setValue && newValue) {
          const newValues = input.setValues(newValue);
          Object.keys(newValues).forEach((key) => {
            setValue(key, newValues[key]);
          });
        }
      }}
      loadingText="Recherche en cours..."
      noOptionsText="Aucun élément trouvé"
      value={{ label: field.value }}
      renderInput={(params) => (
        <TextField
          {...params}
          {...field}
          fullWidth
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};
