import { OrderItem } from "@graphql/*";
import { List, ListItem, ListItemText } from "@mui/material";
import React, { FC } from "react";
import lodash from "lodash";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";

export interface FacturationProps {
  orderItems: OrderItem[];
}

const Facturation: FC<FacturationProps> = ({ orderItems }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const totalPrice = lodash.sum(
    orderItems.map((orderItem) => orderItem.totalPrice)
  );
  const refunds = lodash.sum(orderItems.map((orderItem) => orderItem.refund));
  const billing = totalPrice - refunds;

  return (
    <List>
      <ListItem className={classes.facturation}>
        <ListItemText primary={t("orderItem.list.billing")} />
      </ListItem>
      <ListItem secondaryAction={`+${Number(totalPrice).toFixed(2)}€`}>
        <ListItemText primary={t("orderItem.list.drugPrices")} />
      </ListItem>
      <ListItem
        secondaryAction={`${
          refunds > 0 ? `-${Number(refunds).toFixed(2)}` : 0
        }€`}
      >
        <ListItemText primary={t("orderItem.list.reimbursement")} />
      </ListItem>
      <ListItem
        secondaryAction={`${
          billing > 0 ? `+${Number(billing).toFixed(2)}` : 0
        }€`}
        className={classes.billing}
      >
        <ListItemText primary={t("orderItem.list.finalBilling")} />
      </ListItem>
    </List>
  );
};

export default Facturation;
