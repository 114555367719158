import { ApolloClient } from "@apollo/client";
import { PENDING_ACTIONS_QUERY } from "./gqls";

export const writePendingActions = (
  client: ApolloClient<object>,
  data: any
) => {
  const pendingActions = readPendingActions(client);
  if (pendingActions) {
    client.writeQuery({
      data: {
        pendingActions: [
          ...pendingActions.pendingActions,
          {
            ...data,
            __typename: "PendingActions",
          },
        ],
      },
      query: PENDING_ACTIONS_QUERY,
    });
  } else {
    client.writeQuery({
      data: {
        pendingActions: [
          {
            ...data,
            __typename: "PendingActions",
          },
        ],
      },
      query: PENDING_ACTIONS_QUERY,
    });
  }
};

export const readPendingActions = (client: ApolloClient<object>) => {
  return client.readQuery({ query: PENDING_ACTIONS_QUERY });
};

export const removePendingAction = (
  client: ApolloClient<object>,
  id: string
) => {
  const pendingActions = readPendingActions(client);
  const newPendingActions = pendingActions.pendingActions.filter(
    (pendingAction) => pendingAction.id !== id
  );
  client.writeQuery({
    data: {
      pendingActions: newPendingActions,
    },
    query: PENDING_ACTIONS_QUERY,
  });
};
