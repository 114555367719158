import {
  useCreate_One_TarificationMutation,
  useUpdate_One_TarificationMutation,
  useDelete_One_TarificationMutation,
} from "@graphql/";
import { useApplicationContext } from "@src/context";
import { useTranslation } from "react-i18next";

export const useTarificationMutations = () => {
  const { t } = useTranslation();
  const { notify } = useApplicationContext();
  const [createOneTarification, creatingOneTarification] =
    useCreate_One_TarificationMutation({
      refetchQueries: ["TARIFICATIONS"],
      onCompleted: () => {
        notify({
          message: t("tarifications.success.create"),
          type: "success",
        });
      },
      onError: () => {
        notify({
          message: t("tarifications.errors.create"),
          type: "error",
        });
      },
    });

  const [updateOneTarification, updatingOneTarification] =
    useUpdate_One_TarificationMutation({
      refetchQueries: ["TARIFICATIONS"],
      onCompleted: () => {
        notify({
          message: t("tarifications.success.update"),
          type: "success",
        });
      },
      onError: () => {
        notify({
          message: t("tarifications.errors.update"),
          type: "error",
        });
      },
    });

  const [deleteOneTarification, deletingOneTarification] =
    useDelete_One_TarificationMutation({
      refetchQueries: ["TARIFICATIONS"],
      onCompleted: () => {
        notify({
          message: t("tarifications.success.delete"),
          type: "success",
        });
      },
      onError: () => {
        notify({
          message: t("tarifications.errors.delete"),
          type: "error",
        });
      },
    });

  const loading =
    deletingOneTarification.loading ||
    creatingOneTarification.loading ||
    updatingOneTarification.loading;

  return {
    createOneTarification,
    updateOneTarification,
    deleteOneTarification,
    deletingOneTarification,
    loading,
  };
};
